<template>
	<main class="container">
		<div class="grid grid-3-8">
			<div></div>

			<div class="forms">
				<agreement-details v-model="agreementDetails" @valid="updateFieldValidation('agreementDetails', $event)"></agreement-details>

				<div class="flex justify-end mb-40">
					<div class="btn hover:background-blue-600" :class="{ disabled: !formIsValid }" @click.prevent="createAgreement()">Stovna avtalu</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import axios from 'axios';

import AgreementDetails from '@/components/forms/AgreementDetails.vue';

export default {
	name: 'AgreementCreate',

	components: {
		AgreementDetails,
	},

	data() {
		return {
			agreementDetails: null,

			createOrSearchForCustomer: 'search',

			customerSearchString: '',

			customers: [],

			customer: {
				name: '',
			},

			terminals: [],

			fields: {
				agreementDetails: false,
			},
		};
	},

	mounted() {
		window.addEventListener('click', (e) => {
			for (const activeButton of document.querySelectorAll('.dropdown-button.active')) {
				if (e.target === activeButton) {
					continue;
				}

				activeButton.classList.remove('active');
			}
		});

		if (this.$route.params.customerId) {
			const customerId = this.$route.params.customerId;

			axios
				.get(`/customers/${customerId}`)
				.then((response) => {
					this.agreementDetails.customer = response.data;
				})
				.catch((error) => {
					console.error('Feilur tá vit heintaðu kundan', error);
				});
		}
	},

	watch: {
		customer: {
			deep: true,

			handler() {
				if (this.customer.name) {
					this.fields.customer = true;

					return;
				}

				this.fields.customer = false;
			},
		},

		createOrSearchForCustomer(value) {
			if (value == 'create') {
				this.customer = {};
			}
		},
	},

	computed: {
		formIsValid() {
			return this.fields.agreementDetails;
		},
	},

	methods: {
		createAgreement() {
			if (!this.formIsValid) {
				return false;
			}

			axios
				.post('/agreements', {
					from: this.agreementDetails.from,
					to: this.agreementDetails.to,
					type: this.agreementDetails.agreementType.name,
					tof_number: this.agreementDetails.tof_number,
					swedbank_e_com_number: this.agreementDetails.swedbank_e_com_number,
					swedbank_pos_number: this.agreementDetails.swedbank_pos_number,
					customer: this.agreementDetails.customer,
					terminals: this.terminals,
					stepList: this.agreementDetails.stepList,
					originalFiles: this.agreementDetails.originalFiles,
					terms: this.agreementDetails.terms,
					oneTimePrice: this.agreementDetails.oneTimePrice,
					feePrice: this.agreementDetails.feePrice,
					monthlyPrice: this.agreementDetails.monthlyPrice,
					terminalSupplier: this.agreementDetails.terminalSupplier,
					terminalType: this.agreementDetails.terminalType,
					psp: this.agreementDetails.psp,
					otherPsp: this.agreementDetails.otherPsp,
					isShortTermAgreement: this.agreementDetails.isShortTermAgreement,
					numberOfTerminals: this.agreementDetails.numberOfTerminals,
				})
				.then((response) => {
					this.$router.push({
						name: 'AgreementShow',
						params: {
							id: response.data.id,
						},
					});
				})
				.catch((error) => {
					console.log('error', error);
				});
		},

		sendToCustomer() {
			this.showSendEmailModal = false;
		},

		updateFieldValidation(field, value) {
			this.fields[field] = value;
		},

		search() {
			axios.get(`/customers/search?q=${this.customerSearchString}`).then((response) => {
				this.customers = response.data;
			});
		},

		applySearchResult(customer) {
			this.customerSearchString = '';

			this.customer = customer;
		},

		toggleActiveState(e) {
			if (e.target.classList.contains('active')) {
				e.target.classList.remove('active');

				return;
			}
			e.target.classList.add('active');
		},
	},
};
</script>

<style scoped>
@media (max-width: 992px) {
	.grid {
		display: block;
	}

	aside {
		display: none;
	}
}
</style>
